import React from 'react';
import Container from '@mui/material/Container';
import { Box } from '@mui/material';


function Experiences() {
  return (
    <Box>
        <h1>Work Experience</h1>
    </Box>
  );
}

export default Experiences;

